.searchInputs {
  /* margin-top: 105px; */
  display: flex;
}

/* .search input {
  background-color: white;
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  padding: 15px;
  height: 30px;
  width: 300px;
} */

.searchIcon {
  /* height: 60px; */
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.dataResult {
  width: 550px;
  height: 300px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 100px 105px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 5000;
  position: absolute;
  margin-top: 60px;
  margin-left: 12px;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  color: black;
  margin-left: 30px;
}

.dataItem:hover {
  cursor: pointer;
  background-color: lightgrey;
}

.tableRow:hover {
  background-color: lightgrey;
  cursor: pointer;
}
